<template>
    <MarketingModal :title="data?.card?.item_id" v-if="!settings.is_sourcing_mode">
        <div class="text-center">
                <header class="text-3xl bg-gray-125 p-4 mb-8 at768:text-4xl">
                    <span v-html="stockCountMessage"></span>
                    <p
                        v-if="data.availability?.total > 0"
                        class="mt-2 text-base italic"
                    >
                        <!--                        {{$t("In most cases we have the ability to assemble greater quantities in our MA distribution center, as we frequently stock in component-form. Call 800-847-5625 or ")}}<a href="mailto:u4szkggg@incoming.intercom.io" title="Chat now" class="italic underline-offset-4 underline hover:text-red link prod-inv-close" :data-msg="`I am interested in a stock-plus qty of ${data.card.display_sku}. I need: `">{{ $t('chat') }}</a>{{ $t(" now for the quantity you need") }}.-->
                        {{
                            $t(
                                "In most cases we have the ability to assemble greater quantities in our MA distribution center, as we frequently stock in component-form. Call 800-847-5625 or "
                            )
                        }}<button
                            @click="openChat"
                            class="type-primary underline-offset-4 underline hover:text-red"
                        >
                            {{ $t("chat") }}</button
                        >{{ $t(" now for the quantity you need") }}.
                    </p>
                </header>

                <div
                    v-if="leadTimeMessage"
                    class="text-base flex justify-center items-start gap-1 type-secondary leading-tight mb-8 text-left"
                >
                    <BaseSvg name="icon-clock-icon" class="type-tertiary shrink-0" />
                    <span v-html="leadTimeMessage"></span>
                </div>

                <div
                    v-if="data.availability?.total >= 0 && !is_priority_build"
                    class="border px-4 py-8 at600:px-8"
                >
                    <h4 class="type-secondary mb-4">
                        {{ $t("Warehouses / Estimated Transit Times **") }}
                    </h4>
                    <form
                        class="flex justify-center gap-2 items-center mb-8 text-left"
                        @submit.prevent="getShippingData"
                    >
                        <label
                            for="shipZip"
                            class="font-medium leading-none shrink-0"
                            >{{ $t("Ship To Zip") }}:</label
                        >
                        <div
                            class="h-10 overflow-hidden border-gray-350 duration-150 flex border rounded-sm focus-within:border-royal ring-1 ring-transparent focus-within:ring-royal"
                        >
                            <input
                                id="shipZip"
                                type="text"
                                v-model="shippingForm.ship_to_zip"
                                maxlength="5"
                                minlength="5"
                                pattern="[0-9]{5,5}"
                                class="w-20 text-lg border-none outline-none focus:outline-none focus:ring-0 font-medium at480:w-24"
                            />
                            <button
                                type="submit"
                                class="group px-2 bg-gray-125 active:bg-gray-150"
                            >
                                <BaseSvg
                                    name="icon-recycle-arrows"
                                    class="rotate-90 duration-500 fill-gray group-hover:fill-black"
                                    aria-hidden="true"
                                />
                                <span class="sr-only">Submit</span>
                            </button>
                        </div>
                    </form>
                    <table v-if="arrivalData" class="font-mono m-auto text-sm">
                        <tr
                            v-for="warehouse in config.warehouses"
                            v-show="
                                data.availability?.warehouses?.[warehouse?.code]
                                    ?.available > 0
                            "
                        >
                            <td class="py-1">
                                <span class="flex gap-1">
                                    <BaseSvg
                                        name="icon-marker-icon"
                                        class="text-red"
                                    />
                                    {{ warehouse?.state }}
                                </span>
                            </td>
                            <td class="font-medium pl-4 py-1 at768:pl-8">
                                {{
                                    data.availability?.warehouses?.[
                                        warehouse?.code
                                    ]?.available
                                }}<span
                                    v-if="warehouse?.code === '01' && !is_part"
                                    >+</span
                                >
                            </td>
                            <td
                                class="pl-4 py-1 type-tertiary at768:pl-8"
                                v-if="arrivalData?.[warehouse?.state]?.date"
                            >
                                {{
                                    DateTime.fromFormat(
                                        arrivalData?.[warehouse?.state]?.date,
                                        "yyyyMMdd"
                                    ).toFormat("EEE, MM/dd")
                                }}
                            </td>
                            <td
                                class="pl-4 py-1 type-tertiary at768:pl-8"
                                v-if="arrivalData?.[warehouse?.state]?.date"
                            >
                                {{
                                    arrivalData?.[warehouse?.state]
                                        ?.business_transit_days
                                }}
                                {{ $t("Bus. days") }}
                            </td>
                            <td
                                class="leading-tight pl-4 py-1 type-tertiary text-xs at600:text-base at768:pl-8"
                                v-else
                            >
                                - {{ $t("transit not available") }}
                            </td>
                        </tr>
                    </table>
                    <div v-else class="flex justify-center items-center">
                        <LoadingSpinner :large="true" />
                    </div>
                </div>

                <!-- SEC-2286 -->
                <!--<div v-else-if="is_priority_build" class="border p-6">-->
                <div v-if="is_priority_build" class="border p-6">
                    <p>
                        <button
                            @click="openChat()"
                            class="font-medium underline underline-offset-4 hover:text-red"
                        >
                            {{ $t("Chat now") }}
                        </button>
                        {{ $t("for more information.") }}
                    </p>
                </div>
            <!-- SEC-2286 -->
            <!-- <div v-else class="border p-8"> -->
            <div v-if="!is_priority_build && data.availability?.total <= 0" class="border p-8">
                <p class="text-2xl mb-4">
                    {{ $t("While this product shows out of stock") }}, <em>{{ $t(" most likely") }}</em> {{ $t("the product is still available") }}!
                </p>
                <p class="mb-4">
                    {{
                        $t(
                            "We likely have an exact match or can configure this product from in-stock items to meet exact specs. If we cannot come up with something from stock, we can leverage our factory relationships to get this item in an expedited manner."
                        )
                    }}
                </p>
                <p class="font-semibold">
                    <button
                        @click="openChat()"
                        class="underline underline-offset-4 hover:text-red"
                    >
                        {{ $t("Chat now") }}
                    </button>
                    <span>{{ $t(" to see how we can help!") }}</span>
                </p>
            </div>
        </div>
    </MarketingModal>
    <AppModal
        v-else
        :title="data?.card?.display_sku"
        :read-only="true"
    >
        <template #content>
          <div class="text-left max-w-[700px]">
            <header class="text-2xl p-6">
                <span v-if="data.availability?.total > 0" class="font-bold">{{ data.availability?.total }}</span>
                <span
                    class="font-bold"
                    v-if="
                        Number(data.card?.is_inventory_plus) &&
                        !is_part &&
                        data.availability?.total > 0
                    "
                    >+</span
                >
                <span :class="{ 'capitalize': data.availability?.total <= 0 }">{{ $t(" in stock") }}</span>
                <p
                    v-if="data.availability?.total > 0"
                    class="type-secondary text-base mb-4"
                >
                    <span class="type-primary"
                        >{{ data.availability.total_on_po }}
                        {{ $t("Units") }}</span
                    >
                    {{ $t("currently on order") }}
                </p>
                <p
                    v-if="data.availability?.total > 0"
                    class="text-base type-secondary"
                >
                    {{
                        $t(
                            "In most cases we have the ability to assemble greater quantities in our MA distribution center, as we frequently stock in component-form. Call "
                        )
                    }}<span class="type-primary">800-847-5625</span>
                    {{ $t("or") }}
                    <button
                        @click="openChat()"
                        class="type-primary underline-offset-4 underline hover:text-red"
                    >
                        {{ $t("chat") }}
                    </button>
                    {{ $t(" now for the quantity you need") }}.
                </p>
            </header>
            <div
                v-if="leadTimeMessage"
                class="px-6 text-base flex justify-stat items-center gap-1 type-secondary mb-6"
            >
                <BaseSvg name="icon-clock-icon" class="type-tertiary shrink-0" />
                <span v-html="leadTimeMessage"></span>
            </div>
            <Divider />
            <div v-if="data.availability?.total >= 0 && !is_priority_build" class="p-6">
                <p class="type-secondary mb-2">{{ $t("Ship To Zip") }}</p>
                <div class="flex h-10 justify-center gap-2 items-end mb-4">
                    <AppInput
                        class="w-full h-full grow"
                        v-model="shippingForm.ship_to_zip"
                        minlength="5"
                        type="text"
                        pattern="[0-9]{5,5}"
                    />
                    <button
                        @click="getShippingData"
                        class="btn-app-lg-white-on-gray !duration-0 h-full w-auto"
                    >
                        <BaseSvg name="icon-recycle-arrows" class="rotate-90" />
                    </button>
                </div>
                <div v-if="arrivalData" class="text-base w-full">
                    <div v-for="warehouse in config.warehouses">
                        <div class="flex justify-between items-center py-2">
                            <div
                                class="w-fit flex items-center gap-2 type-secondary text-left"
                            >
                                <BaseSvg
                                    name="icon-marker-icon"
                                    class="text-red"
                                />
                                {{ warehouse?.state }}
                                <span class="font-medium type-primary">
                                    {{
                                        data.availability.warehouses?.[
                                            warehouse?.code
                                        ].available
                                    }}<span
                                        v-if="
                                            warehouse?.code === '01' &&
                                            Number(data.card?.is_inventory_plus) &&
                                            !is_part
                                        "
                                        >+</span
                                    >
                                </span>
                            </div>
                            <div
                                v-if="arrivalData?.[warehouse?.state]?.date"
                                class="text-sm whitespace-nowrap text-right type-tertiary"
                            >
                                {{
                                    DateTime.fromFormat(
                                        arrivalData?.[warehouse?.state]?.date,
                                        "yyyyMMdd"
                                    ).toFormat("EEE, MMMM dd")
                                }}
                                <span
                                    v-if="arrivalData?.[warehouse?.state]?.date"
                                >
                                    ({{
                                        arrivalData?.[warehouse?.state]
                                            ?.business_transit_days
                                    }}
                                    {{ $t("Business days") }})
                                </span>
                            </div>
                            <div
                                v-else
                                class="type-tertiary text-right whitespace-nowrap"
                            >
                                - {{ $t("transit not available") }}
                            </div>
                        </div>
                        <Divider />
                    </div>
                </div>
                <div v-else class="pb-4 pt-8 flex justify-center items-center">
                    <LoadingSpinner :large="true" />
                </div>
            </div>
            <div v-else-if="is_priority_build" class="p-6">
                <p>
                    <button
                        @click="openChat()"
                        class="font-medium underline underline-offset-4 hover:text-red"
                    >
                        {{ $t("Chat now") }}
                    </button>
                    {{ $t("for more information.") }}
                </p>
            </div>
            <div v-else class="p-6">
                <p class="text-2xl mb-4">
                    <span
                        >{{
                            $t("While this product shows out of stock")
                        }},</span
                    >
                    <em>{{ $t(" most likely") }}</em>
                    {{ $t("the product is still available") }}!
                </p>
                <p class="type-secondary mb-4">
                    {{
                        $t(
                            "We likely have an exact match or can configure this product from in-stock items to meet exact specs. If we cannot come up with something from stock, we can leverage our factory relationships to get this item in an expedited manner."
                        )
                    }}
                </p>
                <p class="font-medium">
                    <button
                        @click="openChat()"
                        class="underline underline-offset-4 hover:text-red"
                    >
                        {{ $t("Chat now") }}
                    </button>
                    <span>{{ $t(" to see how we can help!") }}</span>
                </p>
            </div>
        </div>
      </template>
    </AppModal>
</template>

<script setup>
import config from "@/config";
import {
    AppInput,
    BaseSvg,
    Divider,
    LoadingSpinner,
    MarketingModal,
} from "@/Components";
import { useUser, useChat} from "@/Composables";
import { computed, inject, onMounted, ref } from "vue";
import axios from "axios";
import { DateTime } from "luxon";
import _ from "lodash";
import AppModal from "@/Components/Modules/Dialogs/AppModal.vue";

const route = inject("route");
const props = defineProps(["data"]);
const { user, settings } = useUser();
const { openChat } = useChat();

const shippingForm = ref({
    ship_to_zip: user.value.team?.customer?.billing_zipcode.slice(0, 5),
});

const arrivalData = ref(null);

const getShippingData = () => {
    arrivalData.value = null;
    axios
        .post(route("api.v1.public.ups_tnt"), shippingForm.value)
        .then((response) => (arrivalData.value = response.data))
        .catch((error) => console.error(error));
};
const is_part = computed(() => _.get(props.data.card, "filter_sub_category.0", '')?.match(/parts|accessories/i) !== null);
const is_priority_build = computed(() =>
    Number(props.data.card?.is_inventory_plus)
    && !is_part.value
    && props.data.card?.filter_status === 'A'
    && ["LCN", "Von Duprin", "Schlage", "Schlage Electronics", "Sargent"].includes(props.data.card.filter_manufacturer)
);

const stockCountMessage = computed(() => {
    const totalAvailable = props.data?.availability?.total || 0;
    const isInventoryPlus = Number(props.data?.card?.is_inventory_plus);

    // If it’s a priority build, then just say "In Stock"
    if (is_priority_build.value) {
        return 'In Stock';
    }

    // If not a priority build and there are zero units, show "0 in stock"
    if (totalAvailable <= 0) {
        return '<b>0</b> in stock';
    }

    // If inventory plus and totalAvailable > 0, show x+
    if (isInventoryPlus && !is_part.value && totalAvailable > 0) {
        return `<b>${totalAvailable}+</b> in stock`;
    }

    // Otherwise, just show the total
    return `<b>${totalAvailable}</b> in stock`;
});

const leadTimeMessage = computed(() => {
    // No lead time for priority builds
    if (is_priority_build.value) {
        return null
    }

    const averageLeadTime = Number(props.data?.card?.average_lead_time)
    const defaultLeadTime = Number(props.data?.card?.dflt_ldtm_days)
    const relevantLeadTime = averageLeadTime > 0 ? averageLeadTime : defaultLeadTime

    // No lead time if no lead time value
    if (relevantLeadTime <= 0) {
        return null
    }

    // Cap the maximum displayed message at 2 weeks
    if (relevantLeadTime > 13) {
        return "Avg lead time of around <b>2 weeks</b> from the manufacturer"
    }

    return `Avg lead time of around <b>${relevantLeadTime} days</b> from the manufacturer`
})
onMounted(() => getShippingData());

</script>
