<template>
    <div v-if="!featured" class="border-b dark:border-gray-850">
        <breadcrumbs
            :crumbs="crumbs"
            class="hidden at1024:block !border-0"
            h2style="hidden"
        />
        <div class="w-full at1024:w-fit flex justify-center at1024:justify-start gap-2 px-8 my-4 at1024:mt-0 at1024:mb-6">
            <Link :href="route('web.education.index')" class="btn-black-outline-pill h-8 w-fit px-4 active:!bg-gray-125 active:!border-gray-125 active:!shadow-none">
                <BaseSvg name="icon-arrow-backward" aria-hidden="true" />
                All<span class="sr-only"> blog entries</span>
            </Link>
            <Link v-if="previousLink" :href="route('web.education.show', previousLink)" class="btn-black-outline-pill h-8 w-fit px-2 active:!bg-gray-125 active:!border-gray-125 active:!shadow-none">
                <BaseSvg name="icon-chevron-left" aria-hidden="true" />
                <span class="sr-only">Previous article</span>
            </Link>
            <Link v-if="nextLink" :href="route('web.education.show', nextLink)" class="btn-black-outline-pill h-8 w-fit px-2 active:!bg-gray-125 active:!border-gray-125 active:!shadow-none">
                <BaseSvg name="icon-chevron-right" aria-hidden="true" />
                <span class="sr-only">Next article</span>
            </Link>
        </div>
    </div>
    <FeaturedArticleCard v-if="featured && !(searchTerm?.length)" :article="featured" />
    <section class="wrap wrap-huge py-[60px] relative text-base">
        <div class="flex flex-col at1024:flex-row">
            <div class="relative at1024:w-3/4 at1024:ml-0 mb-8">
                <div v-if="searchTerm?.length" class="pb-[30px] mb-[30px] at1024:pb-[60px] at1024:mb-[60px] border-b border-[#eaeaea] w-full">
                    <h1 class="text-[24px] font-medium mb-4 at1024:text-[36px]">{{ $t('Article search') }}: <q>{{ searchTerm }}</q></h1>
                    <p class="flex flex-wrap items-baseline gap-2 text-[18px] text-[#63666a] at1024:text-[23px]">
                        <span v-if="!searchIsFinished" class="opacity-50">Loading…</span>
                        <span v-else>
                            <strong>{{ searchTotalResults }}</strong>
                            {{ searchTotalResults === 1 ? "result" : "results" }}
                        </span>
                        <button class="border-b border-current hover:text-black" @click="clearSearch()">{{ $t('Clear') }}</button>
                    </p>
                </div>

                <template v-if="searchTerm && !searchIsFinished">
                    <LoadingSpinner />
                </template>
                <template v-else-if="searchTerm && searchHasResults">
                    <ArticleCard v-for="article in searchResults" :article="article" :key="article.id"/>
                </template>
                <template v-else-if="searchTerm && searchIsFinished && !searchHasResults">
                    <RecordsNotFound
                        icon="icon-zoom-out"
                        :large="true"
                        header="Can't find what you are looking for?"
                        message="Feel free to contact us for further assistance."
                    >
                        <div>
                            <Link
                                :href="route('web.contact')"
                                class="text-red underline-offset-4 hover:type-primary hover:underline"
                            >
                                {{ $t("Contact Us") }}
                            </Link>
                            {{ $t("now") }}.
                        </div>
                    </RecordsNotFound>
                </template>
                <slot v-else></slot>
            </div>
            <div class="at1024:px-4 relative ml-0 w-full inline-block mb-8 box-border at1024:ml-[5%] at1024:block at1024:w-72 at1200:w-80">
                <div class="w-full page no-print pb-[15px] px-0 block box-border at1200:pt-[30px] at1200:pb-[30px] at1200:pl-[30px] at1200:border-l dark:border-gray-850">
                    <div v-if="categories?.length">
                        <h4 class="text-base type-secondary font-medium mb-5 text-gray-800 whitespace-nowrap">{{ $t('Article Categories') }}</h4>
                        <ul>
                            <template v-for="tag in categories">
                                <li v-if="!tag.name.toLowerCase().includes('employee')" :key="tag.name">
                                    <Link
                                        :href="route('web.education.category.show',tag.slug)"
                                        class="block font-medium px-3 py-2 rounded-sm text-left truncate type-secondary w-full hover:bg-gray-tier-5 hover:type-primary"
                                        v-text="tag.name"
                                    />
                                </li>
                            </template>
                        </ul>
                    </div>
                    <p v-else class="bg-gray-tier-5 p-6 type-secondary">
                        {{ $t('Categories unavailable') }}
                    </p>
                    <div class="mt-5">
                        <SearchInput
                            v-model="searchTerm"
                            :large="true"
                            placeholder="Search articles"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div v-if="article?.related?.length">
        <h5 class="font-bold text-3xl ml-8 at1024:ml-24">{{ $t('You might also like:') }}</h5>
        <ul class="py-4 flex gap-8 items-start overflow-x-auto scroll-pl-8 at1024:scroll-pl-24 snap-x">
            <li v-for="relatedArticle in article.related" class="flex flex-col snap-start shrink-0 w-96 first-of-type:ml-8 at1024:first-of-type:ml-24 last-of-type:mr-8 at1024:last-of-type:mr-24">
                <Link class="group" :href="route('web.education.show', relatedArticle.slug)">
                    <img
                        :src="useAssetTransforms().marketingAssetUrl(relatedArticle.image)"
                        :alt="relatedArticle.image.alt_text"
                        class="w-full border border-keyline-primary group-hover:border-red mb-4"
                    />
                </Link>
                <div
                    v-if="relatedArticle.categories && relatedArticle.categories.length"
                    class="font-medium text-gray"
                >
                    {{ relatedArticle.categories[0]?.name }}
                </div>
                <Link class="group" :href="route('web.education.show', relatedArticle.slug)">
                    <h5 class="type-primary group-hover:text-red text-3xl font-extrabold grow">
                        {{ relatedArticle.title }}
                    </h5>
                </Link>
                <p class="line-clamp-3">{{ relatedArticle.headline }}</p>
                <Link
                    :href="route('web.education.show', relatedArticle.slug)"
                    class="h-full type-secondary text-sm underline underline-offset-4 hover:type-primary"
                >
                    {{ $t('Read More') }}
                </Link>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { computed, inject, onMounted, ref, watch } from "vue";
import {
    Breadcrumbs,
    FeaturedArticleCard,
    BaseSvg,
    SearchInput,
    ArticleCard,
    RecordsNotFound,
    LoadingSpinner,
    Meta
} from "@/Components";
import { router, usePage } from "@inertiajs/vue3";
import { useAppSearchGeneric, useAssetTransforms } from "@/Composables";

const crumbs = computed(() => usePage().props.crumbs);
const categories = computed(() => usePage().props.categories);
const featured = computed(() => usePage().props.featured);
const article = computed(() => usePage().props.article);
const tag = computed(() => usePage().props.tag);

const previousLink = computed(() => usePage().props.previousLink);
const nextLink = computed(() => usePage().props.nextLink);

const route = inject('route')

const {
    searchTerm,
    searchIsFinished,
    searchResults,
    searchTotalResults,
    searchHasResults,
    searchFacets,
    searchWasSearched,
    searchClearFilters,
    searchState,
    searchReset,
    searchSuggestions,
    searchTrackClick,
    searchAppliedFilters,
    searchCurrentPage,
    searchSortBy,
    searchResultsPerPage,
    searchAddFilter,
    searchSetFilter,
    searchRemoveFilter,
    searchFacetLabels,
} = useAppSearchGeneric(DynamicSettings.search.blogSearch);

function clearSearch() {
    searchTerm.value = '';
}

// function updateSearchTerm() {
//     searchTerm.value = searchTermPlaceholder.value
//     searchTermPlaceholder.value = ''
//     document.activeElement.blur()
// }

// function handleSearch() {
//     searchIsLoading.value = true
//     window.scrollTo(0, 0);
//     // Update the URL when changing the query or searching from a category or post page
//     if (!route().current('web.education.index') || searchTermPlaceholder.value !== route().params.q) {
//         router.visit(route('web.education.index', searchTermPlaceholder.value ? { q: searchTermPlaceholder.value } : {}), {
//             onSuccess: () => updateSearchTerm(),
//         })
//     }
// }

// onMounted(()=>{
//     if(searchTerm.value){
//
//     }
// });
//
// // Pull the search out of the query when moving between pages
// router.on('navigate', (event) => {
//     const params = new URLSearchParams(event.target.location.search)
//     if (params.get('q')) {
//         searchTerm.value = params.get('q')
//     } else {
//         searchTerm.value = ''
//     }
// })

// Reset the search when navigating to a category or article
watch(tag, (newTag) => {
    if (newTag && searchTerm.value) searchReset()
})
watch(article, (newArticle) => {
    if (newArticle && searchTerm.value) searchReset()
})

</script>
