<template>
    <AppModal
        :title="computed_title"
        id="printOrderDialog"
        :close-on-success="true"
        :read-only="true"
        @close="forceClose"
        :valid="!inert"
    >
        <template #content>

            <div class="flex flex-col items-stretch p-8 text-sm" >
                <Controls
                    v-model="filters"
                    @csv-export="exportAudit(data.order_id ?? data.quote_id, computed_rows)"
                />

                <div v-if="isLoading" class="pt-8 relative w-full">
                    <div class="pb-16 flex justify-center items-center">
                        <LoadingSpinner :large="true" />
                    </div>
                </div>
                <RecordsNotFound v-else-if="!isLoading && !rows?.length"  header="No audit details available."/>
                <TableNew
                    v-else
                    tbodyClasses="text-base text-sm"
                    :rows="computed_rows"
                    :columns="columns"
                    :row-component="TableRowTwo"
                />
            </div>
        </template>
    </AppModal>
</template>


<script setup>
import {computed, inject, onMounted, reactive, ref} from 'vue';
import {
    AppModal,
    Controls,
    TableNew,
    TableRowTwo,
    LoadingSpinner,
    RecordsNotFound
} from "@/Components";
import {useDialog, useDownload} from "@/Composables";
import axios from "axios";

//ROUTE
const route = inject('route');

//COMPOSABLES
const { forceClose } = useDialog();
const { exportAudit } = useDownload();

//PROPS
const { data } = defineProps({
    data: {
        type: Object,
        required: false,
        default: {
            order_id: null,
            quote_id: null,
        }
    },
});

//REF
const isLoading = ref(true);
const controls = ref('');
const search = ref('');
const rows = ref({});

//COMPUTED
const computed_title = computed(() => {
    return (data.order_id ? 'Order' : 'Quote') + ' # ' + (data.order_id ?? data.quote_id) + ' Audit';
})

//ONMOUNTED
onMounted(() => {
    load_audit()
})

//METHODS
function load_audit() {
    //Order or Quote audit?
    let audit_route =  "web.account.sales.audit." + (data.order_id ? 'order' : 'quote')
    let params = (data.order_id ? {'order':data.order_id} : {'quote':data.quote_id})

    //Request
    axios
        .post(route(audit_route,params))
        .then((response) => rows.value = response.data)
        .catch((response) => { /* no-op */ })
        .finally(() => isLoading.value = false);
}


//Model for Control/Search
let filters = reactive({
    query: "", //Search is bound to this property
    facets: {},
});

const computed_rows = computed(() => {
    if(!isLoading.value) {
        let comp_rows = rows?.value;

        //Search
        let term = filters.query.trim().toLowerCase();
        if(term.length) {
            comp_rows = rows.value.filter((row) => {
                return Object.values(row).find((val) => {
                    let haystack = val;
                    if (typeof haystack === "number") {
                        haystack = haystack.toString();
                    }
                    return haystack.toLowerCase().includes(term);
                });
            });
        }
        return comp_rows;
    }

    return rows.value;
});

const columns = [
    {
        name: "Date",
        sortPath: "",
        path: "date",
    },
    {
        name: "Time",
        sortPath: "",
        path: "time",
    },
    {
        name: "User",
        sortPath: "",
        path: "user_id",
    },
    {
        name: "Type",
        sortPath: "",
        path: "type",
    },
    {
        name: "Seq",
        sortPath: "",
        path: "sequence",
    },
    {
        name: "Desc",
        sortPath: "",
        path: "description",
    },
    {
        name: "Printer",
        sortPath: "",
        path: "printer",
    },
];


</script>
